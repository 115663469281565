/* eslint-disable react-refresh/only-export-components */

import { EditType } from "src/data/editField/editFieldTypes"
import { langKeys } from "src/i18n/langKeys"
import { MText } from "src/ui/MText"

import {
  DeviceSettingMixin,
  IDeviceSettings,
  Input,
  Section,
  SubSection,
} from "./types/deviceSettingTypes"

const SECTION_KEY = "noise monitoring settings"

const SettingNotifyAboutNoise: Input.Dropdown = {
  ...DeviceSettingMixin,
  title: langKeys.device_settings_threshold_sound_switch_title,
  translateDisplayValue: true,
  info: "",
  options: [
    {
      value: "false",
      name: langKeys.device_threshold_noise_notifications_switch_state_only_noise_monitoring,
    },
    {
      value: "true",
      name: langKeys.device_threshold_noise_notifications_switch_state_always,
    },
  ],
  defaultValue: "false",
  id: "reactions.sound_level_high.push",
  type: EditType.DROPDOWN,
  storedValue: (settings: IDeviceSettings): "false" | "true" => {
    const value = !!settings.configuration?.reactions?.find(
      (r) =>
        ["sound_level_high", "sound_level_high_quiet_hours"].includes(r.type) &&
        r.notifications?.find((n) => n === "push")
    )
      ? "true"
      : "false"
    return value
  },
  payload: (value: string, settings: IDeviceSettings) => {
    const notifications: "push"[] = value === "true" ? ["push"] : []
    const reactions =
      settings.configuration?.reactions
        .filter((r) =>
          ["sound_level_high", "sound_level_high_quiet_hours"].includes(r.type)
        )
        .map((reaction) => {
          return { ...reaction, notifications }
        }) || []
    return { reactions }
  },
}

const SettingNoiseAbove: Input.Number = {
  ...DeviceSettingMixin,
  type: EditType.INPUT_NUMBER,
  title: (t) =>
    t(langKeys.device_settings_above, {
      "0": t(langKeys.device_settings_noise),
    }),
  info: langKeys.noise_monitoring_short_description,
  tooltip: (t) => {
    return (
      <div>
        <MText variant="subtitle" color="unset">
          {t(langKeys.noise_monitoring_threshold_guide_title)}
        </MText>
        <p>{t(langKeys.noise_monitoring_unit_description)}</p>
        {t(langKeys.noise_monitoring_threshold_guide_body)
          .split("\n")
          .map((item, i) => {
            return <p key={i}>{item}</p>
          })}
      </div>
    )
  },
  id: "reactions.type.sound_level_high",
  label: "Threshold (dB)",
  unit: "dB",
  max: 120,
  min: 60,
  warningMin: 65,
  step: 1,
  defaultValue: 66,
  storedValue: (settings: IDeviceSettings) => {
    const reactionType = "sound_level_high"
    return (
      settings.configuration?.reactions?.find(
        (reaction) => reaction.type === reactionType
      )?.value || SettingNoiseAbove.defaultValue
    )
  },
  payload: (value: number, settings: IDeviceSettings) => {
    const reactionType = "sound_level_high"
    const reactions =
      settings.configuration?.reactions
        .filter((r) => r.type === reactionType)
        .map((r) => ({ ...r, value })) || []
    return { reactions }
  },
}

const SettingNoiseAboveNight: Input.Number = {
  ...SettingNoiseAbove,
  title: (t) =>
    t(langKeys.device_settings_above, {
      "0": t(langKeys.device_settings_noise),
    }),
  info: "",
  id: "reactions.type.sound_level_high_quiet_hours",
  storedValue: (settings: IDeviceSettings) => {
    const reactionType = "sound_level_high_quiet_hours"
    return (
      settings.configuration?.reactions?.find(
        (reaction) => reaction.type === reactionType
      )?.value || SettingNoiseAboveNight.defaultValue
    )
  },
  payload: (value: number, settings: IDeviceSettings) => {
    const reactionType = "sound_level_high_quiet_hours"
    const reactions =
      settings.configuration?.reactions
        .filter((r) => r.type === reactionType)
        .map((r) => ({ ...r, value })) || []
    return { reactions }
  },
}

const SettingNoiseDuration: Input.Slider = {
  ...DeviceSettingMixin,
  type: EditType.SLIDER,
  id: "reactions.sound_level_high.duration_seconds",
  title: langKeys.device_settings_threshold_sound_duration_title,
  info: langKeys.device_settings_threshold_sound_duration_description,
  max: 15,
  min: 5,
  unit: "minutes",
  defaultValue: 10, // default
  warning:
    "We recommend using a minimum duration of 5 minutes, to avoid false alarms and fast battery drainage",
  storedValue: (settings: IDeviceSettings): number => {
    const durationSeconds = settings.configuration?.reactions?.find((r) =>
      ["sound_level_high", "sound_level_high_quiet_hours"].includes(r.type)
    )?.duration_seconds
    const value = durationSeconds ? Math.round(durationSeconds / 60) : undefined
    return value || SettingNoiseDuration.defaultValue
  },
  payload: (value: number, settings: IDeviceSettings) => {
    const types = ["sound_level_high", "sound_level_high_quiet_hours"]
    const reactions =
      settings.configuration?.reactions
        .filter((r) => types.includes(r.type))
        .map((r) => ({ ...r, duration_seconds: value * 60 })) || []
    return { reactions }
  },
}

const SettingNightTime: Input.RangeSlider = {
  ...DeviceSettingMixin,
  type: EditType.SLIDER_RANGE,
  title: langKeys.when_is_night_time,
  info: "",
  id: "quiet_hours.start-end",
  defaultValue: "22:00-07:00",
  storedValue: (settings: IDeviceSettings): string => {
    const start = settings.configuration?.quiet_hours?.start
    const end = settings.configuration?.quiet_hours?.end
    return start && end ? `${start}-${end}` : SettingNightTime.defaultValue
  },
  payload: (value: string) => {
    const [start, end] = value.split("-")
    return {
      quiet_hours: {
        start,
        end,
        // We can safely assume quiet_hours is enabled if toggling this setting
        enabled: true,
      },
    }
  },
  clockType: undefined,
  populate: (s: IDeviceSettings) => {
    return {
      ...SettingNightTime,
      clockType: s.clockType,
    }
  },
}

const SubSectionNoiseNight: SubSection = {
  type: EditType.SECTION_TOGGLE,
  fields: [SettingNightTime, SettingNoiseAboveNight],
  toggle: {
    ...DeviceSettingMixin,
    type: EditType.TOGGLE,
    id: "quiet_hours.enabled",
    title: langKeys.device_settings_threshold_sound_night_switch_title,
    info: "",
    inlineEdit: true,
    trueVal: true,
    falseVal: false,
    defaultValue: false,
    storedValue: (settings: IDeviceSettings): boolean => {
      return !!settings.configuration?.quiet_hours?.enabled
    },
    payload: (value: boolean, settings: IDeviceSettings) => {
      const [defaultStart, defaultEnd] =
        SettingNightTime.defaultValue.split("-")
      return {
        quiet_hours: {
          enabled: value,
          start: settings.configuration?.quiet_hours?.start || defaultStart,
          end: settings.configuration?.quiet_hours?.end || defaultEnd,
        },
      }
    },
  },
}

export const SectionNoiseMonitoring: Section = {
  type: EditType.SECTION,
  key: SECTION_KEY,
  title: langKeys.sound_noise_monitoring,
  description: "",
  info: "same as in app",
  contents: [
    SettingNotifyAboutNoise,
    SettingNoiseAbove,
    SubSectionNoiseNight,
    SettingNoiseDuration,
  ],
}

import {
  HomeTransfer,
  RemoveDevice,
} from "src/components/Devices/DeviceSettings/DeviceSettingsNodes"
import {
  DEFAULT_REPORT_INTERVAL,
  TReportInterval,
} from "src/data/devices/types/deviceTypes"
import { EditType } from "src/data/editField/editFieldTypes"
import { langKeys } from "src/i18n/langKeys"

import {
  DeviceSettingMixin,
  IDeviceSettings,
  Input,
  Section,
} from "./types/deviceSettingTypes"

const SECTION_KEY = "advanced settings"

const StaticFirmware: Input.Static = {
  ...DeviceSettingMixin,
  type: EditType.STATIC,
  id: "static.firmware",
  title: langKeys.device_settings_firmware_version,
  info: "",
  defaultValue: "N/A",
  inlineEdit: true,
  hideInBulkMode: true,
  payload: () => undefined,
  storedValue: (settings: IDeviceSettings): number =>
    settings.firmware.installed,
}

const StaticMAC: Input.Static = {
  ...DeviceSettingMixin,
  type: EditType.STATIC,
  id: "hello static.mac",
  title: langKeys.device_settings_mac_address,
  info: "",
  defaultValue: "N/A",
  inlineEdit: true,
  hideInBulkMode: true,
  payload: () => undefined,
  storedValue: (settings: IDeviceSettings): string => settings.device_mac,
}

const SettingFrequentSensorUpdates: Input.Toggle = {
  ...DeviceSettingMixin,
  type: EditType.TOGGLE,
  id: "report_interval",
  title: langKeys.device_report_rate_setting_title,
  info: langKeys.device_report_rate_setting_body,
  inlineEdit: true,
  falseVal: "default",
  trueVal: "fast",
  defaultValue: "default",
  storedValue: (settings: IDeviceSettings): TReportInterval =>
    settings?.report_interval || DEFAULT_REPORT_INTERVAL,
  payload: (report_interval: TReportInterval) => ({ report_interval }),
}

export const SectionAdvanced: Section = {
  type: EditType.SECTION,
  key: SECTION_KEY,
  title: langKeys.device_settings_advanced_settings_title,
  contents: [
    StaticFirmware,
    StaticMAC,
    SettingFrequentSensorUpdates,
    HomeTransfer,
    RemoveDevice,
  ],
}

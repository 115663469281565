import { TReactions } from "src/data/devices/types/deviceTypes"
import { EditType } from "src/data/editField/editFieldTypes"
import { langKeys } from "src/i18n/langKeys"

import {
  DeviceSettingMixin,
  IDeviceSettings,
  Input,
  Section,
  SubSection,
} from "./types/deviceSettingTypes"

export const TEMPERATURE_SECTION_KEY = "temperature-section"
export const INDOOR_CLIMATE_TEMPERATURE_SECTION_KEY =
  "indoor-climate-temperature-section"
const TEMPERATURE_SUBSECTION_KEY = "temperature-subsection-key"

const inFahrenheit = (celsius: number) => celsius * (9 / 5) + 32
const inCelsius = (fahrenheit: number) => (fahrenheit - 32) * (5 / 9)

const NotifyAboutTemperature: Input.Toggle = {
  ...DeviceSettingMixin,
  type: EditType.TOGGLE,
  id: "reactions.type.temperature.enabled",
  title: (t) =>
    t(langKeys.device_settings_threshold_switch_title, {
      "0": t(langKeys.temperature),
    }),
  info: langKeys.device_settings_temperature_detail_description,
  inlineEdit: true,
  trueVal: true,
  falseVal: false,
  defaultValue: false,
  storedValue: (settings: IDeviceSettings): boolean => {
    const reactionTypes: TReactions["type"][] = [
      "home:temperature:low",
      "home:temperature:high",
    ]
    const value = !!settings.configuration?.reactions?.find(
      (r) =>
        reactionTypes.includes(r.type) &&
        r.notifications?.find((n) => n === "push") // TODO WEB-xxx: push-critical?
    )
    return value
  },
  payload: (value: boolean, settings: IDeviceSettings) => {
    const notifications: "push"[] = !!value ? ["push"] : []
    const reactionTypes: TReactions["type"][] = [
      "home:temperature:low",
      "home:temperature:high",
    ]
    const reactions = settings.configuration?.reactions
      .filter((r) => reactionTypes.includes(r.type))
      .map((reaction) => ({ ...reaction, notifications }))
    return { reactions }
  },
}

const TemperatureAbove: Input.Number = {
  ...DeviceSettingMixin,
  type: EditType.INPUT_NUMBER,
  title: (t) =>
    t(langKeys.device_settings_above, { "0": t(langKeys.temperature) }),
  info: "",
  id: "reactions.type.home:temperature:high",
  unit: "degrees",
  label: "",
  max: 120,
  min: -60,
  step: 1,
  defaultValue: 30,
  populate: (settings: IDeviceSettings) => {
    const unit = settings.temperature_unit === "F" ? "°F" : "°C"
    const label = ""
    return { ...TemperatureAbove, unit, label }
  },
  storedValue: (settings: IDeviceSettings) => {
    const reactionType: TReactions["type"] = "home:temperature:high"
    let value = settings.configuration?.reactions.find(
      (reaction) => reaction.type === reactionType
    )?.value
    value = value === undefined ? TemperatureAbove.defaultValue : value
    value = settings.temperature_unit === "F" ? inFahrenheit(value) : value
    return Math.round(value)
  },
  payload: (value: number, settings: IDeviceSettings) => {
    const reactionType: TReactions["type"] = "home:temperature:high"
    // If the value has been given in F, convert back to C:
    value =
      settings.temperature_unit === "F" ? Math.round(inCelsius(value)) : value
    // If a threshold is saved, we can safely assume that notifications should
    // also be enabled:
    const notifications: "push"[] = ["push"]
    const reactions = settings.configuration?.reactions
      .filter((r) => r.type === reactionType)
      .map((r) => ({ ...r, notifications, value }))
    return { reactions }
  },
}

const TemperatureBelow: Input.Number = {
  ...TemperatureAbove,
  title: (t) =>
    t(langKeys.device_settings_below, { "0": t(langKeys.temperature) }),
  info: "",
  id: "reactions.type.home:temperature:low",
  defaultValue: 15,
  populate: (settings: IDeviceSettings) => {
    const unit = settings.temperature_unit === "F" ? "°F" : "°C"
    const label = ""
    return { ...TemperatureBelow, unit, label }
  },
  storedValue: (settings: IDeviceSettings) => {
    const reactionType: TReactions["type"] = "home:temperature:low"
    let value = settings.configuration?.reactions.find(
      (reaction) => reaction.type === reactionType
    )?.value
    value = value === undefined ? TemperatureBelow.defaultValue : value
    value = settings.temperature_unit === "F" ? inFahrenheit(value) : value
    return Math.round(value)
  },
  payload: (value: number, settings: IDeviceSettings) => {
    const reactionType: TReactions["type"] = "home:temperature:low"
    // If the value has been given in F, convert back to C:
    value =
      settings.temperature_unit === "F" ? Math.round(inCelsius(value)) : value
    // If a threshold is saved, we can safely assume that notifications should
    // also be enabled:
    const notifications: "push"[] = ["push"]
    const reactions = settings.configuration?.reactions
      .filter((r) => r.type === reactionType)
      .map((r) => ({ ...r, notifications, value }))
    return { reactions }
  },
}

const SubSectionTemperature: SubSection = {
  type: EditType.SECTION_TOGGLE,
  fields: [TemperatureAbove, TemperatureBelow],
  toggle: NotifyAboutTemperature,
}

const SubSectionTemperatureIndoorClimate: Section = {
  key: TEMPERATURE_SUBSECTION_KEY,
  title: "",
  description: langKeys.device_settings_temperature_description,
  type: EditType.SECTION,
  contents: [TemperatureAbove, TemperatureBelow],
}

export const SectionTemperature: Section = {
  type: EditType.SECTION,
  key: TEMPERATURE_SECTION_KEY,
  title: langKeys.temperature,
  contents: [SubSectionTemperature],
}

export const SectionTemperatureIndoorClimate: Section = {
  type: EditType.SECTION,
  key: INDOOR_CLIMATE_TEMPERATURE_SECTION_KEY,
  title: langKeys.temperature,
  contents: [SubSectionTemperatureIndoorClimate],
}

import styled from "styled-components"

import { CircularProgress } from "@material-ui/core"
import { AxiosError } from "axios"
import { useSnackbar } from "notistack"

import { SettingsTabbed } from "src/components/Settings/SettingsTabbed"
import { TReadOnly } from "src/data/commonTypes"
import { useDeviceCache } from "src/data/devices/queries/deviceQueryCache"
import {
  DEFAULT_DEVICE_DESCRIPTION,
  TDevice,
} from "src/data/devices/types/deviceTypes"
import {
  getCurrentConfigs,
  getSections,
} from "src/data/deviceSettings/settings"
import { IDeviceSettingsPayload } from "src/data/deviceSettings/types/deviceSettingTypes"
import { ISection } from "src/data/editField/editFieldTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getTemperatureUnitWithFallback } from "src/data/user/logic/userTemperature"
import { TClockTypeMaybe, TTemperatureUnit } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { Heading2Mixin, MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { debug } from "src/utils/logger"

export function DeviceConfigurationView({
  devices,
  title: viewTitle,
}: {
  devices: TDevice[]
  title?: React.ReactNode
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { t, langKeys } = useTranslate()

  const indoorClimateAvailable = useFeatureAvailability({
    feature: "indoor_climate_monitoring",
  }).available

  const user = useGetUser()
  const { orgId } = useOrganization()
  const deviceCache = useDeviceCache(orgId)
  const temperature_unit = getTemperatureUnitWithFallback(user)
  const clockType = useGetUser().clock_type

  const currentSettings = getCurrentConfigs(devices, {
    clockType,
    temperature_unit,
  })

  const sections: ISection<
    TReadOnly<
      TDevice & {
        temperature_unit: TTemperatureUnit
        clockType: TClockTypeMaybe
      }
    >,
    IDeviceSettingsPayload
  >[] = getSections({ devices, indoorClimateAvailable })

  const subtitle =
    devices.length > 1 ? (
      <MText variant="body" color="secondary">
        {t(langKeys.settings_applying_changes, { amount: devices.length })}
      </MText>
    ) : (
      ""
    )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  function onSubmitFailure(device: TDevice, error: AxiosError<any>) {
    debug.log(error?.response?.data)
    const errorContext = JSON.stringify(error?.response?.data?.context)
    const name: string = device.description ?? DEFAULT_DEVICE_DESCRIPTION
    enqueueSnackbar(
      <div>
        <MText variant="bodyS" color="contrast">
          {t(langKeys.settings_error_updating_device, { name })}
        </MText>
        <MText variant="bodyS" color="contrast">
          {errorContext}
        </MText>
      </div>,
      {
        variant: "error",
        persist: true,
        action: (key) => (
          <MButtonLegacy
            onClick={() => closeSnackbar(key)}
            variant="text"
            color="default"
          >
            Dismiss
          </MButtonLegacy>
        ),
      }
    )
  }

  function onSubmitSuccess(updatedDevice: TDevice) {
    deviceCache.setCachedDeviceData(updatedDevice)
  }

  if (!devices.length) {
    return (
      <Fetching>
        <StyledCircularProgress size={64} />
      </Fetching>
    )
  }

  return (
    <Box>
      <SettingsTabbed
        title={viewTitle && <ViewTitle>{viewTitle}</ViewTitle>}
        views={sections}
        currentSettings={currentSettings}
        config={{
          defaultCallMethod: "put",
          subtitle,
          onSubmitFailure,
          onSubmitSuccess,
          successToast: t(
            langKeys.device_settings_will_be_updated_notification
          ),
          hideDefaultError: true,
        }}
      />
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
`

const Fetching = styled.div`
  font-size: 1rem;
  display: grid;
  place-content: center;
  height: 100%;
`

const ViewTitle = styled.div`
  ${Heading2Mixin};
  padding: ${spacing.M} ${spacing.L};
`

const StyledCircularProgress = styled(CircularProgress)`
  margin-right: ${spacing.L};
`
